import { InferOutput, email, minLength, object, string, pipe, nonEmpty } from 'valibot'
import { reportError } from '~/services/errors'

export const RegisterSchema = object({
  email: pipe(string(), email()),
  name: pipe(string(), nonEmpty()),
  password: pipe(string(), minLength(6))
})

export type RegisterFormType = InferOutput<typeof RegisterSchema>

export interface RegisterError{
  title: string
  description: string
}

const RegisterErrorSchema = [
  'whitelist_block',
  'invalid_session',
  'email_exists'
]

const RegisterErrorContent: Record<string, RegisterError> = {
  'whitelist_block': {
    title: 'Phase de Beta Test',
    description: "Votre email n'est pas encore autorisé à participer à la beta. Si vous êtes inscrit, vous serez contacté par email lors de la prochaine vague d'invitations."
  },
  'invalid_session': {
    title: 'Session Invalide',
    description: "Une problème a été rencontré lors de l'initialisation de votre session"
  },
  'email_exists': {
    title: 'Votre email est déjà enregistré',
    description: "Connectez-vous avez votre compte, ou réinitialisez votre mot de passe."
  }
}

export function getError(code: string){
  if (RegisterErrorSchema.includes(code)){
    return RegisterErrorContent[code]
  }else{
    reportError(new Error(code))
    return {
      title: `Erreur inconnue ${code}`,
      description: 'Une erreur inconnue à été rencontrée, nous faisons tout notre possible pour la corriger.',
    }
  }
}