import RegisterForm from '~/components/forms/account/register-form/register-form'
import Landing from '~/components/landing/landing'

export default function Register() {
  return (
    <Landing>
      <RegisterForm />
    </Landing> 
  )
}
